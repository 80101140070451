html, body {
  height: 100%;
}

#root {
  margin: 1% 5%;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container{
  /* min-height: 10vh; */
  margin:1%;
  height:auto;
  display: inline-flex;
  align-items:left;
  justify-content:left;
}



.top-box {
  display: block;
  font-size: calc(.7 * (1.5vh + 1.1vw));
  background-color: whitesmoke;
}

.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 2fr));
  grid-gap: 0.5%;
  padding-bottom: 1%;
  height: auto;
}

.img-container {
  margin: 1% 10% 0%;
  text-align: center;
}

.img {
  max-width: 100%;
  max-height: 240px;
  height: auto;
  width: auto\9;
  /* ie8 */
}

.left-col {
  height: auto;
  text-align: left;
  word-wrap: break-word;
  font-size: calc(.7 * (1.5vh + 1.1vw));
  background-color: whitesmoke;
  color: black;
  overflow-y: scroll;
  margin-top:1%;
}

.right-col {
  width: 100%;
  padding: 1%;
  margin-top:1%;
  height: auto;
  text-indent: 1;
  text-align: center;
  word-wrap: break-word;
  font-size: calc(.4 * (1.5vh + 1.1vw));
  background-color: whitesmoke;
  color: black;
}


.signature {
  border: 2px solid black;
}

.sigCanvas {
  width: 100%;
  height: 200px;
}

.input-container {
  display: block;
}

.input-container input[type="text"] {
  display: block;
}

.MuiFormControlLabel-label {
  font-size: calc(.7 * (1.5vh + 1.1vw)) !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


h1 {
  text-align: center;
  font-weight: bold;
  margin-top:10px;
  font-size: calc(1.2 * (1.5vh + 1.1vw))
}

p {
  font-size: calc(0.9 * (1.5vh + 1.1vw))
}

@media only screen and (max-width: 500px) {

  .form {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }

  #root {
    margin: 1% 2% 5%;
  }

  .top-box {

    font-size: calc(.9 * (1.5vh + 1.1vw));
  }

  .left-col {
    font-size: calc(.7 * (1.5vh + 1.1vw));

  }

}